import { Map } from 'immutable';

export const SCORE_TRANSFORMATION = 1;
export const RATIO_TRANSFORMATION = 2;
export const INBOUND_COUNT_TRANSFORMATION = 3;
export const OUTBOUND_COUNT_TRANSFORMATION = 4;
export const INBOUND_RATIO_TRANSFORMATION = 5;
export const OUTBOUND_RATIO_TRANSFORMATION = 6;
export const INBOUND_Z_SCORE = 7;
export const OUTBOUND_Z_SCORE = 8;
export const Z_SCORE_MEAN = 9;
export const INBOUND_OUTBOUND_DIFFERENCE_TRANSFORMATION = 10;
export const ROOT_RATIO_TRANSFORMATION = 11;
export const SEGMENT_JOURNEYS_TRANSFORMATION = 12;
export const SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION = 13;
export const SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION = 14;
export const SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION = 15;

export const TRANSFORMATION_NAMES = [
    "Composite Score",
    "Composite Score Ratio",
    "Inbound Count",
    "Outbound Count",
    "Inbound Ratio",
    "Outbound Ratio",
    "Inbound Z Score",
    "Outbound Z Score",
    "Mean Inbound/Outbound Z Score",
    "Inbound/Outbound Difference",
    "Mean Score Ratio",
    "Segment Journeys",
    "Segment Journeys Per Trip",
    "Segment Journeys Per Segment Service Second",
    "Segment Journeys Per Trip Service Second",
];


export function getTransformationsPromise(sectors, sectorValues, possibleCount,
    calculationField, transformationField) {

    return new Promise((resolve, reject) => {
        const transformedValues = getTransformedValues(sectors, sectorValues, possibleCount);
        resolve({
            [calculationField]: {
                [transformationField]: transformedValues
            }
        });
    });
}

function getTransformed(sectors, rawValues, valuator) {
    let maxValue = 0;
    let minValue = 0;
    const values = sectors.map(sector => {
        const id = sector.id;
        const bounds = sector.bounds;
        const sectorValue = rawValues[id];
        const value = valuator(sectorValue)

        if (value > maxValue) {
            maxValue = value;
        }

        if (value < minValue) {
            minValue = value;
        }

        return { "id": id, "bounds": bounds, "value": value };
    }).sort((s1, s2) => s1.value - s2.value).map((s, i) => {
        return { ...s, "rank": i }
    }).sort((s1, s2) => s1.id - s2.id);

    const [minScale, maxScale] = getScaleValues(minValue, maxValue);
    return { "min": minScale, "max": maxScale, "values": values };
}

function getTransformedAndAggregated(sectors, rawValues, valuator) {
    let maxValue = 0;
    let minValue = 0;
    let sum = 0;
    const values = sectors.map(sector => {
        const id = sector.id;
        const bounds = sector.bounds;
        const sectorValue = rawValues[id];
        const value = valuator(sectorValue)
        sum += value;

        if (value > maxValue) {
            maxValue = value;
        }

        if (value < minValue) {
            minValue = value;
        }

        return { "id": id, "bounds": bounds, "value": value };
    }).sort((s1, s2) => s1.value - s2.value).map((s, i) => {
        return { ...s, "rank": i }
    }).sort((s1, s2) => s1.id - s2.id);

    const [minScale, maxScale] = getScaleValues(minValue, maxValue);
    const avg = sum / sectors.length;

    return { "min": minScale, "max": maxScale, "values": values, "sum": sum, "avg": avg };
}

function getScaleValues(minValue, maxValue) {
    let minScale;
    let maxScale;
    if (minValue < 0 && maxValue > 0) {
        if (-minValue > maxValue) {
            minScale = minValue;
            maxScale = -minValue;
        } else {
            minScale = -maxValue;
            maxScale = maxValue;
        }
    } else {
        if (maxValue > 0) {
            maxScale = maxValue;
            minScale = 0;
        } else if (minValue < 0) {
            maxScale = 0;
            minScale = minValue;
        } else {
            maxScale = 0;
            minScale = 0;
        }
    }
    return [minScale, maxScale];
}

export function getTransformationMerge(
    sectors, transformations, baselineTransformations) {

    const baselineTransformationsMaps = baselineTransformations.map(
        (transformation) => {
            const transformedSectorsMap = transformation.values.reduce(
                (map, sector) => {
                    map[sector.id] = sector.value;
                    return map;
                }, {});
            return transformedSectorsMap;
        });

    const transformationsMaps = transformations.map(
        (transformation) => {
            const transformedSectorsMap = transformation.values.reduce(
                (map, sector) => {
                    map[sector.id] = sector.value;
                    return map;
                }, {});
            return transformedSectorsMap;
        });

    const differences = transformationsMaps.map(
        (transformationValues, transformationType) => {
            const baselineTransformationValues
                = baselineTransformationsMaps.get(transformationType);

            let max = 0;
            let min = 0;
            const sectorDifferenceValues
                = sectors.map((sector) => {
                    const sectorId = sector.id;
                    const sectorValue = transformationValues[sectorId] || 0;
                    const baselineSectorValue
                        = baselineTransformationValues[sectorId] || 0;

                    const difference = sectorValue - baselineSectorValue;

                    if (difference > max) {
                        max = difference;
                    }
                    if (difference < min) {
                        min = difference;
                    }
                    return {
                        "id": sectorId, "bounds": sector.bounds,
                        "value": difference
                    };
                }).sort((s1, s2) => s1.value - s2.value).map((s, i) => {
                    return { ...s, "rank": i }
                }).sort((s1, s2) => s1.id - s2.id);;
            const [minScale, maxScale] = getScaleValues(min, max);
            return { "values": sectorDifferenceValues, "min": minScale, "max": maxScale }
        });

    return differences;
}


function getScores(sectors, rawValues) {
    const valuator = (sectorValue) => {
        let outbound;
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
            inbound = 0;
        }

        const value = (inbound * outbound);
        return value;
    }

    return getTransformed(sectors, rawValues, valuator)
}

function getRatios(sectors, rawValues, possibleSectorReaches) {
    const valuator = (sectorValue) => {
        let outbound;
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
            inbound = 0;
        }

        const value = (inbound * outbound) / (possibleSectorReaches * possibleSectorReaches);
        return value;
    }
    return getTransformedAndAggregated(sectors, rawValues, valuator)
}

function getRootRatios(sectors, rawValues, possibleSectorReaches) {
    const valuator = (sectorValue) => {
        let outbound;
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
            inbound = 0;
        }

        const value = Math.sqrt(inbound * outbound) / possibleSectorReaches;
        return value;
    }
    return getTransformedAndAggregated(sectors, rawValues, valuator)
}

function getInboundJourneys(sectors, rawValues) {
    const valuator = (sectorValue) => {
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
        } else {
            inbound = 0;
        }

        return inbound;
    }
    return getTransformedAndAggregated(sectors, rawValues, valuator)
}

function getOutboundJourneys(sectors, rawValues) {
    const valuator = (sectorValue) => {
        let outbound;
        if (sectorValue) {
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
        }

        return outbound;
    }
    return getTransformedAndAggregated(sectors, rawValues, valuator)
}


function getInboundRatios(sectors, rawValues, possibleSectorReaches) {
    const valuator = (sectorValue) => {
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
        } else {
            inbound = 0;
        }

        return inbound / possibleSectorReaches;
    }
    return getTransformedAndAggregated(sectors, rawValues, valuator)
}


function getOutboundRatios(sectors, rawValues, possibleSectorReaches) {
    const valuator = (sectorValue) => {
        let outbound;
        if (sectorValue) {
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
        }

        return outbound / possibleSectorReaches;
    }
    return getTransformedAndAggregated(sectors, rawValues, valuator)
}

function getInboundOutboundDifference(sectors, rawValues) {
    const valuator = (sectorValue) => {
        let inbound;
        let outbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
            outbound = sectorValue.outboundCount;
        } else {
            inbound = 0;
            outbound = 0;
        }

        return inbound - outbound;
    }
    return getTransformedAndAggregated(sectors, rawValues, valuator)
}

function getMeanZScore(sectors, rawValues) {
    const numSectors = sectors.length;

    let mean = 0;
    sectors.forEach(sector => {
        const sectorId = sector.id;
        const sectorValue = rawValues[sectorId];
        let inbound;
        let outbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
            outbound = sectorValue.outboundCount;
        } else {
            inbound = 0;
            outbound = 0;
        }
        mean += (Math.sqrt(inbound * outbound) / numSectors);
    });

    let variance = 0;
    sectors.forEach(sector => {
        const sectorId = sector.id;
        const sectorValue = rawValues[sectorId];
        let inbound;
        let outbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
            outbound = sectorValue.outboundCount;
        } else {
            inbound = 0;
            outbound = 0;
        }
        const value = Math.sqrt(inbound * outbound);
        variance += (Math.pow(value - mean, 2) / numSectors);
    });
    const stdDev = Math.sqrt(variance);


    const valuator = (sectorValue) => {
        let inbound;
        let outbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
            outbound = sectorValue.outboundCount;
        } else {
            inbound = 0;
            outbound = 0;
        }
        const value = Math.sqrt(inbound * outbound);
        return (value - mean) / stdDev;
    }
    return getTransformed(sectors, rawValues, valuator)
}

function getInboundZScore(sectors, rawValues) {
    const numSectors = sectors.length;

    let mean = 0;
    sectors.forEach(sector => {
        const sectorId = sector.id;
        const sectorValue = rawValues[sectorId];
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
        } else {
            inbound = 0;
        }
        mean += (inbound / numSectors);
    });

    let variance = 0;
    sectors.forEach(sector => {
        const sectorId = sector.id;
        const sectorValue = rawValues[sectorId];
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
        } else {
            inbound = 0;
        }
        const value = inbound;
        variance += (Math.pow(value - mean, 2) / numSectors);
    });
    const stdDev = Math.sqrt(variance);


    const valuator = (sectorValue) => {
        let inbound;
        if (sectorValue) {
            inbound = sectorValue.inboundCount;
        } else {
            inbound = 0;
        }
        const value = inbound;
        return (value - mean) / stdDev;
    }
    return getTransformed(sectors, rawValues, valuator)
}


function getOutboundZScore(sectors, rawValues) {
    const numSectors = sectors.length;

    let mean = 0;
    sectors.forEach(sector => {
        const sectorId = sector.id;
        const sectorValue = rawValues[sectorId];
        let outbound;
        if (sectorValue) {
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
        }
        mean += (outbound / numSectors);
    });

    let variance = 0;
    sectors.forEach(sector => {
        const sectorId = sector.id;
        const sectorValue = rawValues[sectorId];
        let outbound;
        if (sectorValue) {
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
        }
        const value = outbound;
        variance += (Math.pow(value - mean, 2) / numSectors);
    });
    const stdDev = Math.sqrt(variance);


    const valuator = (sectorValue) => {
        let outbound;
        if (sectorValue) {
            outbound = sectorValue.outboundCount;
        } else {
            outbound = 0;
        }
        const value = outbound;
        return (value - mean) / stdDev;
    }
    return getTransformed(sectors, rawValues, valuator)
}


export function getTransformedValues(sectors, sectorValues,
    possibleSectorReaches) {
    const sectorMap = sectorValues.reduce((accumulator, value) => {
        accumulator[value.sector] = value;
        return accumulator;
    }, {});
    return Map().withMutations(map => {
        map.set(SCORE_TRANSFORMATION,
            getScores(sectors, sectorMap))
            .set(RATIO_TRANSFORMATION,
                getRatios(sectors, sectorMap, possibleSectorReaches))
            .set(INBOUND_COUNT_TRANSFORMATION,
                getInboundJourneys(sectors, sectorMap))
            .set(OUTBOUND_COUNT_TRANSFORMATION,
                getOutboundJourneys(sectors, sectorMap))
            .set(INBOUND_RATIO_TRANSFORMATION,
                getInboundRatios(sectors, sectorMap, possibleSectorReaches))
            .set(OUTBOUND_RATIO_TRANSFORMATION,
                getOutboundRatios(sectors, sectorMap, possibleSectorReaches))
            .set(INBOUND_OUTBOUND_DIFFERENCE_TRANSFORMATION,
                getInboundOutboundDifference(sectors, sectorMap))
            .set(Z_SCORE_MEAN, getMeanZScore(sectors, sectorMap))
            .set(INBOUND_Z_SCORE, getInboundZScore(sectors, sectorMap))
            .set(OUTBOUND_Z_SCORE, getOutboundZScore(sectors, sectorMap))
            .set(ROOT_RATIO_TRANSFORMATION,
                getRootRatios(sectors, sectorMap, possibleSectorReaches));
    });
}

export function getTransformationsForContext(
    isSegments, isComparison, isExpanded, isExhaustive) {
    let transformationTypes;
    if (isSegments) {
        transformationTypes = [
            SEGMENT_JOURNEYS_TRANSFORMATION,
            SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION,
            SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION,
            SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION
        ];
    } else if (!isComparison && !isExpanded && isExhaustive) {
        transformationTypes = [
            ROOT_RATIO_TRANSFORMATION,
            RATIO_TRANSFORMATION,
            SCORE_TRANSFORMATION,
            INBOUND_Z_SCORE,
            OUTBOUND_Z_SCORE,
            Z_SCORE_MEAN,
            INBOUND_COUNT_TRANSFORMATION,
            OUTBOUND_COUNT_TRANSFORMATION,
            INBOUND_RATIO_TRANSFORMATION,
            OUTBOUND_RATIO_TRANSFORMATION,
            INBOUND_OUTBOUND_DIFFERENCE_TRANSFORMATION,
        ];
    } else {
        transformationTypes = [
            ROOT_RATIO_TRANSFORMATION,
            RATIO_TRANSFORMATION,
            SCORE_TRANSFORMATION,
            INBOUND_COUNT_TRANSFORMATION,
            OUTBOUND_COUNT_TRANSFORMATION,
            INBOUND_RATIO_TRANSFORMATION,
            OUTBOUND_RATIO_TRANSFORMATION,
        ];
    }
    return transformationTypes;
}

export function getTransformationsMapForContext(
    isSegments, isComparison, isExpanded, isExhaustive) {
    const transformations = getTransformationsForContext(
        isSegments, isComparison, isExpanded, isExhaustive);
    const transformationMap = transformations.reduce(
        (base, transformation) => {
            base[transformation] = TRANSFORMATION_NAMES[transformation - 1];
            return base;
        }, {});
    return transformationMap;
}