import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useParams,
    Redirect
} from "react-router-dom";
import AccessTool from './access-tool'
import Container from 'react-bootstrap/Container'

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/react-datetime/css/react-datetime.css'
import UnScoreItClient from './unscoreit'
import UnGridItClient from './ungridit'
import UnRideItClient from './unrideit'
import UnGateItClient from './ungateit'
import TokenProvider from './token-provider'
import AuthControl from './auth-control'
import AccessViewer from './access-viewer'
import NetworkViewer from './network-view';
import SegmentJourneysViewer from './segment-journeys-viewer.js'
import RouteServiceTimesViewer from './route-service-times';

import {
    AuthorizationServiceConfiguration,
    RedirectRequestHandler,
    AuthorizationRequest,
    LocalStorageBackend,
    DefaultCrypto,
    FetchRequestor
} from '@openid/appauth'
import { NoHashQueryStringUtils } from './query.tsx'


class Workshop extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userProfile: undefined
        };

        this.setUserProfile = (provider) => {
            this.unGateItClient.getProfile(provider).then((response) => {
                this.setState({ "userProfile": response.data });
            });
        };

        this.clearUserProfile = () => {
            this.setState({ "userProfile": undefined })
        }

        this.authorizationHandler = new RedirectRequestHandler(
            new LocalStorageBackend(),
            new NoHashQueryStringUtils(),
            window.location,
            new DefaultCrypto());

        this.tokenProvider = new TokenProvider(
            this.authorizationHandler,
            this.setUserProfile,
            this.clearUserProfile);
        this.unScoreItClient = new UnScoreItClient();
        this.unGridItClient = new UnGridItClient();
        this.unRideItClient = new UnRideItClient();
        this.unGateItClient = new UnGateItClient();

    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact={true} path={[
                        "/access"]}>
                        <AccessToolWrapper
                            userProfile={this.state.userProfile}
                            unGridItClient={this.unGridItClient}
                            unRideItClient={this.unRideItClient}
                            unScoreItClient={this.unScoreItClient}
                            unsetCalculation={this.unsetCalculation}
                            authorizationHandler={this.authorizationHandler}
                            tokenProvider={this.tokenProvider} />
                    </Route>
                    <Route path={["/access/view/:path/:id/baseline/:basePath/:baseId",
                        "/access/view/:path/:id"
                    ]}>
                        <AccessViewerWrapper
                            unGridItClient={this.unGridItClient}
                            unRideItClient={this.unRideItClient}
                            unScoreItClient={this.unScoreItClient}
                        />
                    </Route>
                    <Route exact={true} path="/auth">
                        <AuthWrapper
                            userProfile={this.state.userProfile}
                            unGridItClient={this.unGridItClient}
                            unRideItClient={this.unRideItClient}
                            unScoreItClient={this.unScoreItClient}
                            calculationUpdater={this.updateCalculation}
                            authorizationHandler={this.authorizationHandler}
                            tokenProvider={this.tokenProvider} />
                    </Route>
                    <Route exact={true} path="/logout">
                        <Redirect to='/' />
                    </Route>
                    <Route exact={true} path="/callback">
                        <CallbackWrapper
                            userProfile={this.state.userProfile}
                            unGridItClient={this.unGridItClient}
                            unRideItClient={this.unRideItClient}
                            unScoreItClient={this.unScoreItClient}
                            calculationUpdater={this.updateCalculation}
                            tokenProvider={this.tokenProvider}
                        />
                    </Route>
                    <Route exact={true} path={[
                        "/network/:networkId"]}>
                        <NetworkViewerWrapper
                            userProfile={this.state.userProfile}
                            unRideItClient={this.unRideItClient}
                            authorizationHandler={this.authorizationHandler}
                            tokenProvider={this.tokenProvider} />
                    </Route>
                    <Route exact={true} path={["/route-service-times/:path/:id/durations/:duration"]}>
                        <RouteServiceTimesWrapper
                            unRideItClient={this.unRideItClient}
                            unScoreItClient={this.unScoreItClient}
                        />
                    </Route>
                    <Route exact={true} path={["/route-service-times/:path/:id/sectors/:sector/durations/:duration"]}>
                        <RouteServiceTimesWrapper
                            unRideItClient={this.unRideItClient}
                            unScoreItClient={this.unScoreItClient}
                        />
                    </Route>
                    <Route exact={true} path={["/segment-journeys/:path/:id/durations/:duration"]}>
                        <SegmentJourneysViewerWrapper
                            rideClient={this.unRideItClient}
                            scoreClient={this.unScoreItClient}
                            gridClient={this.unGridItClient}
                        />
                    </Route>
                    <Route exact={true} path={["/route-service-times/:path/:id"]}>
                        <RouteServiceTimesWrapper
                            unRideItClient={this.unRideItClient}
                            unScoreItClient={this.unScoreItClient}
                        />
                    </Route>
                    <Route exact={true} path="/">
                        <Teaser />
                    </Route>
                </Switch>
            </Router>
        );
    }

    setCalculation(calculationId, calculationName, calculationPath) {
        this.setState({
            calculationId: calculationId,
            calculationName: calculationName,
            calculationPath: calculationPath
        });
    }
}

function Teaser(props) {
    return (
        <Container>
            <div className="container-fluid text-sm-center p-5 bg-light">
                <h1 className="text-center"><a href='https://busgraphs.com'>BusGraphs</a> Access Analysis Tool</h1>
            </div>
        </Container>);
}

function CallbackWrapper(props) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code && !props.userProfile) {
        props.tokenProvider.requestToken(code);
        return null;
    }
    return (<Redirect to={localStorage.getItem("returnUrl")} />);
}

function AuthWrapper(props) {
    if (!props.userProfile) {
        AuthorizationServiceConfiguration.fetchFromIssuer(
            process.env.REACT_APP_COGNITO_CONFIG_URL,
            new FetchRequestor())
            .then(response => {
                const configuration = response;

                const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
                const redirectUri = process.env.REACT_APP_COGNITO_CALLBACK_URL;
                const scope = "profile openid";

                let authRequest = new AuthorizationRequest({
                    client_id: clientId,
                    redirect_uri: redirectUri,
                    scope: scope,
                    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
                    state: undefined,
                    extras: { 'prompt': 'consent', 'access_type': 'offline' }
                });

                props.authorizationHandler.performAuthorizationRequest(
                    configuration, authRequest);
            });
        return null;
    } else {
        return (<Redirect to='/' />);
    }
}

function RouteServiceTimesWrapper(props) {
    const { path, id, sector, duration } = useParams();

    return (
        <RouteServiceTimesViewer
            unRideIt={props.unRideItClient}
            unScoreIt={props.unScoreItClient}
            calculationId={id}
            calculationPath={path}
            sector={sector}
            duration={duration} />
    )
}

function SegmentJourneysViewerWrapper(props) {
    const { path, id, duration } = useParams();
    return (
        <SegmentJourneysViewer
            rideClient={props.rideClient}
            scoreClient={props.scoreClient}
            gridClient={props.gridClient}
            calculationId={id}
            calculationPath={path}
            duration={duration} />
    )
}

function AccessViewerWrapper(props) {
    const { path, id, basePath, baseId } = useParams();
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <AccessViewer
                calculationId={id}
                path={path}
                baselineCalculationId={baseId}
                baselinePath={basePath}
                unScoreIt={props.unScoreItClient}
                unGridIt={props.unGridItClient}
                unRideIt={props.unRideItClient}
            />
        </div>
    )
}

function NetworkViewerWrapper(props) {
    const { networkId } = useParams()
    return (
        <NetworkViewer
            networkId={networkId}
            unRideIt={props.unRideItClient}
        />
    )
}

function AccessToolWrapper(props) {
    const location = useLocation();

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Container fluid style={{ display: "flex", justifyContent: "flex-end" }}>
                <AuthControl
                    tokenProvider={props.tokenProvider}
                    location={location}
                    userProfile={props.userProfile}
                    authorizationHandler={props.authorizationHandler} />

            </Container>

            <AccessTool
                userProfile={props.userProfile}
                unScoreIt={props.unScoreItClient}
                unGridIt={props.unGridItClient}
                unRideIt={props.unRideItClient}
                tokenProvider={props.tokenProvider} />
        </div>);
}

ReactDOM.render(
    <Workshop />,
    document.getElementById('root')
);
