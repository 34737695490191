import React from 'react'

import Form from 'react-bootstrap/Form'

import {
    SCORE_TRANSFORMATION,
    RATIO_TRANSFORMATION,
    INBOUND_COUNT_TRANSFORMATION,
    OUTBOUND_COUNT_TRANSFORMATION,
    INBOUND_RATIO_TRANSFORMATION,
    OUTBOUND_RATIO_TRANSFORMATION,
    Z_SCORE_MEAN,
    ROOT_RATIO_TRANSFORMATION,
    INBOUND_OUTBOUND_DIFFERENCE_TRANSFORMATION,
    OUTBOUND_Z_SCORE,
    INBOUND_Z_SCORE,
    SEGMENT_JOURNEYS_TRANSFORMATION,
    SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION,
    SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION,
    SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION
} from "./sector-transformer";


function formatNumber(number, transformationType) {
    let formatted;
    switch (transformationType) {
        case RATIO_TRANSFORMATION:
        case INBOUND_RATIO_TRANSFORMATION:
        case OUTBOUND_RATIO_TRANSFORMATION:
        case Z_SCORE_MEAN:
        case INBOUND_Z_SCORE:
        case OUTBOUND_Z_SCORE:
        case ROOT_RATIO_TRANSFORMATION:
            formatted = number.toFixed(4);
            break;
        case SCORE_TRANSFORMATION:
        case INBOUND_COUNT_TRANSFORMATION:
        case OUTBOUND_COUNT_TRANSFORMATION:
        case INBOUND_OUTBOUND_DIFFERENCE_TRANSFORMATION:
        case SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION:
        case SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION:
        case SEGMENT_JOURNEYS_TRANSFORMATION:
        case SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION:
            formatted = Intl.NumberFormat(undefined, {
                notation: "compact"
            }).format(number);
            break;

        default:
            formatted = number;
    }
    return formatted;
}

class Scale extends React.PureComponent {

    constructor(props) {
        super(props);

        this.handleUpdateEvent = (event) => {
            props.setTransformationType(parseInt(event.target.value));
        }
    }



    render() {
        const colorsThresholds = this.props.colorMapping.getColorThresholds();

        let label;
        if (this.props.transformationTypes.length > 0 && colorsThresholds.length > 0) {
            label = (
                <Form>
                    <Form.Select
                        value={this.props.transformationType}
                        onChange={this.handleUpdateEvent}>
                        {this.props.transformationTypes
                            .map((entry) => {
                                const [type, name] = entry;
                                return (
                                    <option key={type} value={type}>
                                        {name + (this.props.isComparison ? " Difference" : "")}
                                    </option>
                                );
                            })}
                    </Form.Select>
                </Form>
            );
        } else {
            label = null;
        }

        return (
            <div style={{ "display": "flex", "justifyContent": "flex-end" }}>
                {label}
                {colorsThresholds.map(threshold => {
                    return (
                        <div key={threshold['color']} style={{
                            "minWidth": 80,
                            "height": 40,
                            "backgroundColor": threshold["color"],
                            "color": "#666666",
                            "fontWeight": "bold"
                        }}>
                            {threshold['comparator']
                                + formatNumber(threshold['value'], this.props.transformationType)}
                        </div>
                    );
                })}
            </div>
        );
    }


}

export default Scale;