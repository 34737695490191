import { deepDictMerge, merge } from './merge'
import { isEntirelyExhaustive } from './calculation'
import { getExpandedReachCountsPromise, getExpandedTransformationsPromise } from "./expansion";
import { getTransformationMerge, getTransformationsForContext } from './sector-transformer';

export function setExpansionUpdateInterval(component) {
    setInterval(() => {
        if (component.state.loadingExpansion && (component.state.expandedSector !== undefined)) {
            setExpandedSector(component, component.state.expandedSector)
        }
    }, 10000)
}

export function setViewport(component, viewport) {
    component.setState({ "viewport": viewport });
}

export function setSector(component, sector) {
    component.setState({ "selectedSector": sector });
}

function isExpansionComplete(calculation, baseline) {
    let completed = true;
    if (calculation.get('id')) {
        const status = calculation.get('expansionStatus');
        completed = (status === "COMPLETE") || (status === "ERROR");
    }
    if (baseline.get('id')) {
        const status = baseline.get('expansionStatus');
        completed = (status === "COMPLETE") || (status === "ERROR");
    }
    return completed;
}

export function setExpandedSector(component, sector) {
    setLoadingExpansion(component)
    const sectorIndex = sector - 1;
    const promises = [];
    const baselineId = component.state.baseline.get('id');
    if (component.state.calculation.get('id')) {
        promises.push(getExpandedReachCountsPromise(
            component,
            component.state.calculation.get('id'),
            sectorIndex,
            "calculation",
            component.state.calculation.get("selectedDuration")
        ));
    }
    if (baselineId) {
        promises.push(getExpandedReachCountsPromise(
            component,
            baselineId,
            sectorIndex,
            "baseline",
            component.state.baseline.get("selectedDuration")
        ))
    }
    Promise.all(promises).then(states => {
        const update = states.reduce((flat, statePart) => {
            return deepDictMerge(flat, statePart);
        }, {});

        const newCalculation = component.state.calculation.merge(
            update.calculation);
        const newBaseline = component.state.baseline.merge(
            update.baseline);

        const newState = {
            "loadingExpansion": !isExpansionComplete(newCalculation, newBaseline),
            "expandedSector": sector,
            "calculation": newCalculation,
            "baseline": newBaseline
        }
        return newState
    }).then((calculationState) => {
        const transformationsPromise = getExpandedTransformationsPromise(
            component, calculationState, "calculation");
        let baselineTransformationsPromise;
        if (baselineId) {
            baselineTransformationsPromise = getExpandedTransformationsPromise(
                component, calculationState, "baseline");
        } else {
            baselineTransformationsPromise = Promise.resolve({});
        }
        return Promise.all([Promise.resolve(calculationState),
            transformationsPromise, baselineTransformationsPromise]);
    }).then(states => {
        const newCalculation = states.reduce((accumulator, current) => {
            if (current) {
                return accumulator.merge(current.calculation);
            }
            return accumulator;
        }, component.state.calculation);

        const newBaseline = states.reduce((accumulator, current) => {
            if (current) {
                return accumulator.merge(current.baseline);
            }
            return accumulator;
        }, component.state.baseline);

        const newState = merge(states);
        newState.calculation = newCalculation;
        newState.baseline = newBaseline;

        if (baselineId) {
            newState.expandedSectorTransformations
                = getTransformationMerge(component.state.sectors,
                    newState.calculation.get("expandedSectorTransformations"),
                    newState.baseline.get("expandedSectorTransformations"));
        } else {
            newState.expandedSectorTransformations = newCalculation.get(
                "expandedSectorTransformations");
        }
        const existingTransformation = component.transformationType;

        const newValidTransformations = getTransformationsForContext(
            false, !!baselineId, true, isEntirelyExhaustive(
                component.state.calculation,
                component.state.baseline));

        if (!newValidTransformations.includes(existingTransformation)) {
            newState.transformationType = newValidTransformations[0];
        }
        component.setState(newState);
    });
}

export function clearExpandedSector(component) {
    component.setState({ "expandedSector": undefined })
}

export function setLoadingExpansion(component) {
    component.setState({ "loadingExpansion": true })
}

export function clearLoadingExpansion(component) {
    component.setState({ "loadingExpansion": false })
}

export function setTransformationType(component, type) {
    component.setState({ "transformationType": type });
}

export function getInServiceTimePromise(component, calculationId, path, duration, serviceTimeField) {
    return component.props.unScoreIt.getServiceTime(calculationId, path, duration).then(res => {
        return {
            [serviceTimeField]: parseInt(res.data)
        };
    })
}