import { getTransformationsPromise } from './sector-transformer';


export function getExpandedReachCountsPromise(component, calculationId,
    sector, calculationField, duration) {
    return component.props.unScoreIt.getSectorReachCounts(
        calculationId, sector, duration).then((res) => {
            const response = res.data
            const reachList = response.sectorReaches;
            const counts = [];

            reachList.forEach(reach => {
                const sector = reach.sector;
                const outbound = reach.outboundCount;
                const inbound = reach.inboundCount;
                counts[sector] = {
                    "inboundCount": inbound,
                    "outboundCount": outbound
                };
            });

            return {
                [calculationField]: {
                    "expansionStatus": response.status,
                    "expandedSectorValues": reachList
                }
            };
        });
}

export function getExpandedTransformationsPromise(component, calculationState,
    calculationField) {

    const sectorValues
        = calculationState[calculationField].get("expandedSectorValues");

    return getTransformationsPromise(component.state.sectors, sectorValues,
        component.state[calculationField].get("times"), calculationField,
        "expandedSectorTransformations");
}
