import { PureComponent } from "react";
import { merge } from "./merge";
import AccessMap, { SEGMENT_SELECTION_MODE } from "./map";
import { DefaultColorScheme } from './color';
import { Map } from "immutable";
import { RingSpinnerOverlay } from 'react-spinner-overlay';
import { SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION, SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION, SEGMENT_JOURNEYS_TRANSFORMATION, SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION, getTransformedValues, ROOT_RATIO_TRANSFORMATION } from "./sector-transformer";

class SegmentJourneysViewer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            "segmentRecords": [],
            "viewport": {
                "latitude": 47.60663847499262,
                "longitude": -122.33468081908542,
                "zoom": 14,
            },
            "sectors": [],
            "centerSectors": [],
            "centerPoints": [],
            "hoveredSegment": undefined,
            "selectedSegment": undefined,
            "expandingSegment": undefined,
            "segmentTransformation": SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION,
            "sectorTransformation": ROOT_RATIO_TRANSFORMATION,
            "totalJourneys": 0,
            "sectorTransformations": undefined,
            "segmentTransformations": undefined
        }

        setInterval(() => {
            if (this.state.expandingSegment !== undefined) {
                this.selectSegment(this.state.expandingSegment);
            }
        }, 10_000);


        this.setViewport = (viewport) => {
            this.setState({ "viewport": viewport });
        }

        this.getCalculationPromise = () => {
            return this.props.scoreClient.getCalculation(
                this.props.calculationId,
                this.props.calculationPath).then((response) => {
                    return {
                        "calculation": response.data
                    };
                });
        };

        this.segmentJourneysPromise = () => {
            return this.props.scoreClient.getSegmentJourneys(
                this.props.calculationId,
                this.props.calculationPath,
                this.props.duration).then((response) => {
                    return {
                        "segmentJourneys": response.data
                    };
                });
        };


        this.getServiceLinestringsPromise =
            (serviceId) => {
                return this.props.rideClient.getServiceLinestrings(serviceId).then((response) => {
                    return {
                        "linestrings": response.data
                    };
                });
            };

        this.getServicePromise = (serviceId) => {
            return this.props.rideClient.getService(serviceId).then((response) => {
                return {
                    "service": response.data
                };
            });
        };

        this.getSectorsPromise = (grid) => {
            return this.props.gridClient.getSectors(grid).then((sectorRes) => {
                const reducer = (numberedSectors, sector) => {
                    numberedSectors[sector.id] = sector;
                    return numberedSectors;
                };

                const sectors = sectorRes.data.reduce(reducer, []);
                return { "sectors": sectors };
            });
        };

        this.getRoutesForRoutingPromise = (serviceId) => {
            return this.props.rideClient.getServiceRoutesForRoutings(serviceId)
                .then((response) => {
                    return {
                        "routesForRoutings": response.data
                    };
                });
        };

        this.getSegmentServiceTimesPromise = () => {
            return this.props.scoreClient.getSegmentServiceTimes(
                this.props.calculationId,
                this.props.calculationPath,
                this.props.duration
            ).then((response) => {
                const segmentServiceTimes = {};
                response.data.forEach(element => {
                    if (!(element.routingId in segmentServiceTimes)) {
                        segmentServiceTimes[element.routingId] = []
                    }

                    segmentServiceTimes[element.routingId][element.segment] =
                    {
                        "serviceTime": element.serviceTime,
                        "tripCount": element.tripCount,
                        "routingServiceTime": element.routingServiceTime,
                    }
                });
                return {
                    "segmentServiceTimes": segmentServiceTimes
                };
            });
        }

        this.hoverSegment = (segmentInfo) => {
            this.setState({ "hoveredSegment": segmentInfo });
        };

        this.clickSegment = (segmentInfo) => {
            if (segmentInfo.segments.length > 0) {
                const segment = segmentInfo.segments[0];
                const routing = segment.routingId;
                const sequence = segment.sequence;

                if (this.state.selectedSegment === undefined ||
                    (routing === this.state.selectedSegment.routing &&
                        sequence === this.state.selectedSegment.sequence)) {
                    this.setState({
                        "expandingSegment": segment
                    });
                    this.selectSegment(segment);
                } else {
                    this.setState({
                        "selectedSegment": undefined,
                        "sectorTransformations": undefined
                    });
                }
            }
        }

        this.selectSegment = (segment) => {
            const routing = segment.routingId;
            const sequence = segment.sequence;
            this.props.scoreClient.getSegmentSectors(
                this.props.calculationId,
                this.props.calculationPath,
                routing,
                sequence,
                this.props.duration).then((res) => {
                    const status = res.data.status;
                    const reaches = res.data.sectorReaches;
                    if (status === "COMPLETE") {
                        const transformedValues = getTransformedValues(
                            this.state.sectors,
                            reaches,
                            this.state.totalJourneys);
                        console.log("complete, setting state");
                        this.setState({
                            "selectedSegment": segment,
                            "sectorTransformations": transformedValues,
                            "expandingSegment": undefined,
                        });
                    } else if (status !== "ERROR") {
                        this.setState({
                            "expandingSegment": segment,
                        })
                    }
                });
        }

        this.setTransformationType = (transformation) => {
            const type = (this.state.sectorTransformations !== undefined) ? "sectorTransformation" : "segmentTransformation";

            this.setState({ [type]: transformation });
        }
    }

    componentDidMount() {
        Promise.all([
            this.getCalculationPromise(),
            this.segmentJourneysPromise(),
            this.getSegmentServiceTimesPromise()])
            .then((results) => {
                const result = merge(results);
                const serviceId = result.calculation.serviceId;
                const gridId = result.calculation.gridId;

                return Promise.all([
                    Promise.resolve(result),
                    this.getServiceLinestringsPromise(serviceId),
                    this.getRoutesForRoutingPromise(serviceId),
                    this.getSectorsPromise(gridId)])
            }).then((results) => {
                const result = merge(results);

                const centers = (this.props.calculationPath === "network") ?
                    result.calculation.sectorCount : (2 * result.calculation.centerCount);
                const totalJourneys = result.calculation.sectorCount * result.calculation.totalTimes * centers;

                const segmentRecords = result.segmentJourneys.segmentJourneys
                    .filter((record) => result.linestrings[record.routing].length > 0)
                    .map((record) => {
                        const routingId = record.routing;
                        const sequence = record.segment;
                        const linestring = result.linestrings[routingId];
                        const segmentPoints = linestring[sequence];

                        const routes = result.routesForRoutings[routingId];

                        const data = result.segmentServiceTimes[routingId][sequence];
                        return {
                            "points": segmentPoints,
                            "journeys": record.count,
                            "routingId": routingId,
                            "routes": routes,
                            "sequence": sequence,
                            "tripCount": data.tripCount,
                            "serviceTime": data.serviceTime,
                            "routingServiceTime": data.routingServiceTime
                        }
                    });



                const state = { "segmentRecords": segmentRecords, "totalJourneys": totalJourneys, "sectors": result.sectors };
                if (result.calculation.centerSectors) {
                    state["centerSectors"] = result.calculation.centerSectors;
                }
                if (result.calculation.centerPoints) {
                    state["centerPoints"] = result.calculation.centerPoints;
                }
                this.setState(state)
            });

    }

    render() {
        let journeyRatioMin = 0;
        let journeyRatioMax = 0;
        const journeyRatioValues = this.state.segmentRecords.map(record => {
            const value = record.journeys;
            if (value > journeyRatioMax) {
                journeyRatioMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime
            };
        });

        let journeysPerTripMin = 0;
        let journeysPerTripMax = 0;
        const journeysPerTripValues = this.state.segmentRecords.map(record => {
            const value = record.journeys / record.tripCount;
            if (value > journeysPerTripMax) {
                journeysPerTripMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime
            };
        });

        let journeysPerSegmentServiceSecondMin = 0;
        let journeysPerSegmentServiceSecondMax = 0;
        const journeysPerSegmentServiceSecondValues = this.state.segmentRecords.map(record => {
            const value = record.journeys / record.serviceTime;
            if (value > journeysPerSegmentServiceSecondMax) {
                journeysPerSegmentServiceSecondMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime
            };
        });

        let journeysPerServiceSecondMin = 0;
        let journeysPerServiceSecondMax = 0;
        const journeysPerServiceSecondValues = this.state.segmentRecords.map(record => {
            const value = record.journeys / record.routingServiceTime;
            if (value > journeysPerServiceSecondMax) {
                journeysPerServiceSecondMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime
            };
        });

        const segmentTransformations = Map().withMutations(map => {
            map.set(SEGMENT_JOURNEYS_TRANSFORMATION, {
                "values": journeyRatioValues,
                "min": journeyRatioMin,
                "max": journeyRatioMax
            })
                .set(SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION, {
                    "values": journeysPerTripValues,
                    "min": journeysPerTripMin,
                    "max": journeysPerTripMax
                })
                .set(SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION, {
                    "values": journeysPerSegmentServiceSecondValues,
                    "min": journeysPerSegmentServiceSecondMin,
                    "max": journeysPerSegmentServiceSecondMax
                })
                .set(SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION, {
                    "values": journeysPerServiceSecondValues,
                    "min": journeysPerServiceSecondMin,
                    "max": journeysPerServiceSecondMax
                })
        });

        const transformation = (this.state.sectorTransformations !== undefined) ?
            this.state.sectorTransformation :
            this.state.segmentTransformation;

        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                <RingSpinnerOverlay
                    loading={this.state.expandingSegment !== undefined}
                    color="#000000" />
                <AccessMap
                    doubleClickZoom={false}
                    visible={true}
                    width="auto"
                    height="auto"
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                    colorScheme={new DefaultColorScheme()}
                    viewport={this.state.viewport}
                    setViewport={this.setViewport}
                    selectionMode={SEGMENT_SELECTION_MODE}
                    expandedSectorTransformations
                    ={[]}
                    baselineCenterPoints
                    ={[]}
                    baselineCenterSectors
                    ={[]}
                    minRouteZoom={0}
                    loadingExpansion={false}
                    sectors={this.state.sectors}
                    calculationCenterSectors={this.state.centerSectors}
                    calculationCenterPoints={this.state.centerPoints}
                    selectSegment={this.clickSegment}
                    selectedSegment={this.state.selectedSegment}
                    hoverSegment={this.hoverSegment}
                    hoveredSegment={this.state.hoveredSegment}
                    transformationType={transformation}
                    segmentTransformations={Map(segmentTransformations)}
                    setTransformationType={this.setTransformationType}
                    sectorTransformations={this.state.sectorTransformations}
                />
            </div >
        )
    }
}

export default SegmentJourneysViewer